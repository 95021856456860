/* Center the login container on the screen */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #007bff, #6610f2);
}

/* Style for the login form */
.login-form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

/* Center the heading inside the form */
.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Style for input fields */
.login-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  box-sizing: border-box;
}

/* Style for the login button */
.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

/* Style for error messages */
.error {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

/* Style for success messages */
.success {
  color: green;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

/* Style for "Forgot Password?" link */
.forgot-password-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 14px;
  display: block;
  text-align: center;
  margin: 10px 0;
}

/* Style for additional links (Sign Up, SafeRide) */
.login-form a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  display: block;
  margin-top: 10px;
}

.login-form a:hover {
  text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .login-form {
    padding: 15px;
  }

  .login-form h2 {
    font-size: 18px;
  }

  .login-form input {
    font-size: 12px;
  }

  .login-form button {
    font-size: 14px;
    padding: 8px;
  }

  .forgot-password-link {
    font-size: 12px;
  }

  .login-form a {
    font-size: 12px;
  }
}
