.add-reserve-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease;
}

.add-reserve-container:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18);
}

.add-reserve-form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Creates consistent spacing between form elements */
}

.add-reserve-form input[type="text"],
.add-reserve-form input[type="email"],
.add-reserve-form input[type="tel"],
.add-reserve-form input[type="date"],
.add-reserve-form input[type="time"],
.add-reserve-form textarea {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

/* Improving focus state for accessibility */
.add-reserve-form input[type="text"]:focus,
.add-reserve-form input[type="email"]:focus,
.add-reserve-form input[type="tel"]:focus,
.add-reserve-form input[type="date"]:focus,
.add-reserve-form input[type="time"]:focus,
.add-reserve-form textarea:focus {
  border-color: #0056b3;
  outline: none; /* Removing default focus outline to use custom border color */
}

.add-reserve-form textarea {
  resize: vertical; /* Allows users to resize the textarea vertically */
}

.add-reserve-form button {
  padding: 12px;
  background-color: #007bff; /* Slightly brighter blue for better visibility */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-reserve-form button:hover {
  background-color: #0069d9;
  transform: translateY(-2px); /* Subtle lift effect on hover */
}

.add-reserve-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Adding a subtle animation to form inputs for a more dynamic feel */
.add-reserve-form input[type="text"],
.add-reserve-form input[type="email"],
.add-reserve-form input[type="tel"],
.add-reserve-form input[type="date"],
.add-reserve-form input[type="time"],
.add-reserve-form textarea {
  animation: fadeIn 0.6s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
