/* General Ride Cell Styling */
.ride-modern {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
  max-width: 500px;
  margin: 15px auto;
  font-family: 'Arial', sans-serif;
}

.ride-modern:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Ride Header Styling */
.ride-modern-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ride-modern-locations {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.ride-arrow {
  margin: 0 8px;
  font-size: 1.2rem;
  color: #999;
}

/* Seats Section with Conditional Coloring */
.ride-modern-seats {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  font-weight: bold;
}

.seats-available {
  color: #28a745; /* Green for available seats */
}

.seats-unavailable {
  color: #dc3545; /* Red for 0 seats */
}

.ride-modern-seats i {
  font-size: 1rem;
}

/* Ride Details Styling */
.ride-modern-details {
  margin-top: 10px;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
  font-size: 0.9rem;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.ride-date-time {
  font-weight: bold;
  color: #333;
}

.ride-donation,
.ride-additional-info,
.ride-user {
  color: #555;
}

.ride-donation strong,
.ride-additional-info strong,
.ride-user strong {
  font-weight: bold;
  color: #333;
}

/* Fulfill Request Button */
.ride-modern-book-btn {
  margin-top: 12px;
  padding: 10px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.ride-modern-book-btn:hover {
  background-color: #0056b3;
}
