.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f2f5;
}

.profile-header {
  text-align: center;
  padding: 20px;
  background: linear-gradient(180deg, #ffffff 0%, #f0f2f5 100%);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  animation: fadeIn 0.5s ease;
}

.profile-header h1 {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.profile-header p {
  color: #555;
  font-size: 18px;
  margin: 5px 0;
}

.section {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.section h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.item-card {
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 10px;
  animation: fadeIn 0.5s ease;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); /* Smoother transition */
  width: 100%;
  max-width: 400px; /* Adjusted max-width for smaller item cards */
}

.item-card:hover {
  transform: translateY(-5px); /* Floating effect */
}

.item-card p {
  margin: 5px 0;
  color: #666;
}

.item-card strong {
  color: #333;
}

.profile-header button,
.item-card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.profile-header button:hover,
.item-card button:hover {
  background-color: #0056b3;
}

.item-card button.delete-button,
.item-card button.cancel-button {
  background-color: #dc3545; /* Red color for delete button */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor on hover */
  font-size: 16px; /* Font size */
  margin-top: 10px; /* Top margin */
}

.item-card button.delete-button:hover,
.item-card button.cancel-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .profile-container,
  .profile-header,
  .section {
    width: 90%;
    max-width: unset;
  }

  .profile-header,
  .item-card {
    padding: 10px;
  }
}
