.rides-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.filter-container {
  margin-bottom: 20px;
  text-align: center;
}

.filter-container label {
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-container input[type="checkbox"] {
  margin-right: 8px;
}

.ride-modern {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ride-modern:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.ride-modern-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ride-locations {
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
}

.ride-arrow {
  margin: 0 10px;
  color: #999;
}

.ride-seats {
  font-weight: bold;
  font-size: 1rem;
}

.seats-available {
  color: #28a745;
}

.seats-unavailable {
  color: #dc3545;
}

.ride-details {
  margin-top: 10px;
  color: #555;
}

.ride-donation strong,
.ride-info strong {
  color: #333;
}

.add-ride-button,
.book-seat-button {
  padding: 10px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.add-ride-button:hover,
.book-seat-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.confirm-btn {
  background-color: #28a745;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #dc3545;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #c82333;
}
