.addsaferide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .addsaferide-container h1 {
    color: #007bff;
    margin-bottom: 20px;
  }
  
  form {
    width: 100%;
  }
  
  form div {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }
  
  input[type='time'],
  input[type='date'],
  input[type='number'] {
    width: 100%;
    padding: 8px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 14px;
  }
  
  button[type='submit'] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  
  h3 {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  p {
    margin: 0;
    color: #555;
  }
  