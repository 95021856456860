.logout-container {
    position: fixed;
    right: 20px; /* Adjust according to your layout */
    top: 20px; /* Adjust according to your layout */
    z-index: 100; /* Makes sure it's above other content */
}

.saferide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 600px; /* Adjusted max-width for smaller ride container */
    margin: auto;
}

.driver-info {
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.driver-info:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.driver-info h3 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 5px;
}

.driver-info p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-top: 5px;
}

.button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 15px;
}

.button:hover {
    background-color: #0056b3;
}

.button.logout-button {
    background-color: #dc3545;
}

.button.logout-button:hover {
    background-color: #c82333;
}
